.filme-info{
    margin-top: 18px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    max-width: 800px;
    padding: 0 8px;
}

.filme-info h1{
    margin: 14px 0;
    color:white;
}

.filme-info h3{
    color:white;
    margin-top: 14px;
}

.filme-info strong{
    color:white;
}

.filme-info span{
    color:white;
    margin: 8px 0;
}

.buttons button{
    margin-right: 4px;
    margin-top: 14px;
    margin-left: 0px;
}

.comentarios {
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px
}

.nome-nota {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.filme-info img.servicosStream {
    width: 60px;
    border-radius: 12px;
    padding: 4px;
}

.filme-info {
    margin-top: 18px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    max-width: 800px;
    padding: 0 26px;
}

.filme-info h3 {
    color: white;
    margin-top: 14px;
    font-size: 22px;
}

.filme-info img{
    border-radius:20px;
    width: 900px;
    max-width: 100%;
    max-height: 340px;
    object-fit: cover;
}

.capaFilme{
    margin-bottom: 10px;
}
