body{
    background-color: #232027;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lista-filmes{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: center;
}
   
.card{
    width: 300px;
    height: 554px;
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: rgb(0, 0, 0);
    border: 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
}

.card:hover{
    transition: 0.5s;
    background-color: rgb(0, 5, 57);
    border: 3px solid rgb(176, 176, 255);
    box-shadow: 10px 10px 27px 5px rgba(255, 255, 255, 0.73);

}

.lista-filmes strong{
    color:white;
    margin-bottom: 12px;
    text-align: center;
    font-size: 22px;
    display: block;
    margin-top: 20px;
}

.lista-filmes a{
    text-decoration: none;
    color: white;
}

.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    font-size: 140%;
    color: white;
}

.descricao h4 {
    color: rgb(224, 222, 222);
    font-size: 20px;
    font-weight: 400;
}

.descricao{
    margin-left: 24px;
    margin-top: 20px;
}