

.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

div#offcanvasNavbar-expand-xl {
    background: black;
    color: white;
}
.Nav.Link{
    color:black !important;
}

.logoIf{
    padding: 20px;
}

.mais a {
    color: black;
}

.mais a:hover {
    color: black;
    font-weight: bold;
}

@media only screen and (max-width: 991px) {
    .d-flex {
        display: flex!important;
        flex-direction: column;
        align-items: flex-start;
    }
}

.LogoTitulo {
    display: flex;
    align-items: center;
}

b.TituloAzul {
    color: #4575ff;
}