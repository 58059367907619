.meus-filmes{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.meus-filmes h1{
    margin-bottom: 20px;
    color:white;
}

.meus-filmes ul > li > span {
    font-size: 18px;
    color: white;
    margin-right: 10vw;
}

.semFilme{
    font-size: 18px;
    margin: 0px;
    color: white;
}


.meus-filmes ul li{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;    
    margin-right: 14px; 
}

.meus-filmes li{
    padding: 10px 20px 10px 20px;
    background-color: rgb(33 37 41);
    border-radius: 10px;
}

.meus-filmes div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.meus-filmes div a{
    text-decoration: none;
    color: rgb(43, 43, 255);
    margin-right: 10px;
}

.meus-filmes button{
    font-size: 14px;
    padding: 6px;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: rgb(60, 60, 250);
}