

.Topo-comentarios {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-bottom: 30px;
    align-items: baseline;
}

.adicionarComment{
    font-size: 17px !important;
}

.NewComment{
    font-size: 28px;
    padding-bottom: 10px;
    font-weight: bold;
}

body.modal-open {
    padding: 0px !important;
}

@media (max-width: 500px) {
    .modal-comentario .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 300px) {
    .modal-comentario .modal-dialog {
      max-width: 100%;
      margin: 0;
    }
  }

  @media (max-width: 500px) {
    .modal-content {
      max-width: 90vw;
      max-height: 90vh;
    }
  }
  
@media (max-width: 300px) {
.modal-content {
    max-width: 80vw;
    max-height: 80vh;
}
}

.modal-content {
    margin-top: 20px;
}