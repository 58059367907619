body{
    background-color: #232027;
}
img#slider {
    border-radius: 10px;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.lista-filmes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: center;
}
   
.card-filme img{
    width: 24vw;
    max-width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
}

.card-filme{
    margin: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    margin-left: 10px;

}

.card-filme:hover{
    transition: 0.5s;
    box-shadow: 2px 2px 20px 2px rgba(90, 151, 255, 0.73);
}

.lista-filmes strong{
    color:white;
    margin-bottom: 12px;
    text-align: center;
    font-size: 22px;
    display: block;
    margin-top: 20px;
}

.lista-filmes a{
    text-decoration: none;
    color: white;
}

.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    font-size: 140%;
    color: white;
}

.carousel-caption {
    background: #0000005c;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.descricaoHome{
    margin-top: 30px;
    color: white;
    font-size: 1.3rem;
}

@media only screen and (max-width: 780px) {
    .descricaoHome{
        margin-top: 20px;
        color: white;
        font-size: 1.0rem;
    }
  }
 