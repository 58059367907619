

.avaliacoes {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}
  
.like, .deslike {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.numLikes, .numDislikes {
    margin: 0;
}

.deslike{
    width: 34px;
}

.like{
    width: 34px;
}

p.numDislikes {
    position: absolute;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

p.numLikes {
    position: absolute;
    padding-top: 12px;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

img.botaoLike{
    border-radius: 0px;
    background: #ffffff;
}

img.botaoDislike{
    border-radius: 0px;
    background: #ffffff;
}

button.like {
    border: none;
    background: none;
    /* color: #0088ff; */
}

button.deslike {
    border: none;
    /* color: #0088ff; */
    margin-left: 10px;
    margin-top: 15px;
}

.comentarios strong{
    color: black !important;
}

.comentarios h3{
    color: black;
    font-weight: 400;
    font-size: 22px;
}

button.like:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
}

button.deslike:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
}

.comentarios {
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: 13px -9px 18px 0px rgba(0, 109, 252, 0.324);
    -moz-box-shadow: 13px -9px 18px 0px rgba(0, 109, 252, 0.324);
    box-shadow: 13px -9px 18px 0px rgba(0, 109, 252, 0.297);
}

