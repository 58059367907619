*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.App {
  background-color: #181818;
}
html{
  background-color: #181818;
}
body{
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.label.form-label {
  color: white;
}

.app{
  display: flex;
  background-color: #0a090bdc;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.meus-filmes {
  margin-bottom: 300px;
}